import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, Row, FormGroup, Label, Input } from 'reactstrap';
import { PanelHeader, Spinner } from '../../../components';
import Select from 'react-select';
import 'moment/locale/fr';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { one } from '../../../helpers/actions/projects';
import OrderCard from './OrderCard';
import { orderResponsible, orderStatus } from '../../../helpers/nomenclators';

class Orders extends Component {
  state = {
    loading: false,
    project: null,
    filters: {
      name: null,
      status: null,
      responsible: null
    }
  };

  load() {
    this.setState(ps => ({ ...ps, loading: true }));

    let { id } = this.props.match.params,
      { dispatch } = this.props;
    dispatch(
      one(id, {
        include: ['devis', 'devisBase'],
        fields: ['type', 'name', 'status', 'devis', 'devisBase']
      })
    ).then(project => {
      let active =
        project && project.devis && project.devis.length
          ? project.devis[0]._id.toString()
          : '';
      this.setState(ps => ({ ...ps, project, active, loading: false }));
    });
  }

  componentDidMount() {
    this.load();
  }

  render() {
    let { loading, project, filters } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}

          <Card>
            <CardBody>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Label>
                      <Trans>Search</Trans>
                    </Label>
                    <Input
                      type="text"
                      value={filters.material}
                      onChange={event =>
                        this.setState({
                          filters: {
                            ...filters,
                            material: event.target.value
                          }
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                  <FormGroup>
                    <Label>
                      <Trans>Responsible</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={orderResponsible.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      value={filters.responsible}
                      onChange={event =>
                        this.setState({
                          filters: {
                            ...filters,
                            responsible: event ? event.value : null
                          }
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={2}>
                  <FormGroup>
                    <Label>
                      <Trans>Status</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={orderStatus.map(p => ({
                        label: t(p),
                        value: p
                      }))}
                      value={filters.status}
                      onChange={event =>
                        this.setState({
                          filters: {
                            ...filters,
                            status: event ? event.value : null
                          }
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {project && project.devis && project.devis.length
            ? project.devis.map((d, key) => {
                return (
                  <OrderCard
                    key={key}
                    projectType={project.type}
                    devis={d}
                    filters={filters}
                  />
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Orders));
